<template>
  <div>
    <!-- 每个平台表单 -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 名称 -->
      <a-form-item
        label="名称"
        name="name"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.name"
        />
      </a-form-item>
      <!-- 唯一标识 -->
      <a-form-item
        label="唯一标识"
        name="app_sign"
      >
        <a-input
          placeholder="请输入"
          v-model:value="formState.app_sign"
        />
      </a-form-item>
      <!-- 配置项 -->
      <a-form-item
        label="配置类型"
        name="config_ids"
      >
        <a-select
          v-model:value="formState.config_ids"
          mode="multiple"
          placeholder="请选择"
          :filter-option="filterOption"
        >
          <a-select-option
            v-for="item in configs"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      
      <!-- <template v-for="item in ConfigTypes">
        <a-form-item
          :label="item.text"
        >
          <a-select
            v-model:value="supplierID"
            placeholder="请选择"
          >
            <a-select-option v-for="item in ConfigTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
          </a-select>
        </a-form-item>
      </template> -->
      <!-- 保存 -->
      <a-form-item
        style="display: flex; justify-content: center;"
      >
        <a-button
          type="primary"
          :loading="isLoading"
          @click="touchSubmit"
        >
          确定
        </a-button>
      </a-form-item>
    </a-form>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </div>
</template>

<script setup>
import { ref, reactive, defineExpose, onBeforeMount, onBeforeUnmount } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { projectDevelopmentDetail, projectConfigAll, projectDevelopmentEdit } from '@/api/system'

// 定义参数
const props = defineProps({
  // 项目ID
  project: {
    type: Number,
    default: 0
  },
  // 平台类型
  platform: {
    type: Number,
    default: 0
  }
})
// emit
const emit = defineEmits(['success'])
// 加载
let isLoading = ref(false)
// 配置列表
let configs = ref([])
// 参数
let formState = reactive({
  // 当前配置ID
  id: undefined,
  // 名称
  name: undefined,
  // 唯一标识
  app_sign: undefined,
  // 配置ID
  config_ids: []
})

onBeforeMount(() => {
  // 清空
  formState.id = undefined
  formState.name = undefined
  formState.app_sign = undefined
  formState.config_ids = []
  // 获取配置列表
  getProjectConfigAll()
  // 获取详情
  getProjectDevelopmentDetail()
})

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 获取配置列表
function getProjectConfigAll () {
  projectConfigAll().then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      configs.value = data
    } else {
      message.error(res.message || msg)
    }
  })
}

// 获取详情
function getProjectDevelopmentDetail () {
  // 加载
  isLoading.value = true
  const params = {
    project_id: props.project,
    platform_id: props.platform
  }
  projectDevelopmentDetail(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      // 没有配置 data === null
      if (data) {
        formState.id = data.id
        formState.name = data.name
        formState.app_sign = data.app_sign
        formState.config_ids = data.config_ids
      }
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 提交
function touchSubmit () {
  // 加载
  isLoading.value = true
  // 参数
  let params = {
    project_id: props.project,
    platform_id: props.platform,
    ...formState
  }
  // 保存
  projectDevelopmentEdit(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      message.success('保存成功')
      // 更新数据
      getProjectDevelopmentDetail()
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    message.error('保存失败')
  })
}

</script>

<style lang="less" scoped>
.kuaishou-module-title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
}
</style>